import { Link, useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../../components/layout';
import ServiceHero from '../../components/service-hero';
import ServiceHeroMobile from '../../components/service-hero-mobile';
import Button from 'react-bootstrap/Button';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { Helmet } from 'react-helmet';
import ImageSection from '../../components/image-section';
import TextSection from '../../components/text-section';
import ServiceCard from '../../components/service-card';
import SVGFastResponse from '../../components/svg/fast-response-svg';
import SVGMP1 from '../../components/svg/mp1-svg';
import SVGMP2 from '../../components/svg/mp2-svg';
import SVGMP3 from '../../components/svg/mp3-svg';
import SVGPainting from '../../components/svg/painting-svg';
import SVGSuspended from '../../components/svg/suspended-svg';
import SVGGlazing from '../../components/svg/glazing-svg';
import SVGTiling from '../../components/svg/tiling-svg';
import SVGFlooring from '../../components/svg/flooring-svg';
import SVGFire from '../../components/svg/fire-svg';
import SVGSecurity from '../../components/svg/security-svg';
import SVGPlumbing from '../../components/svg/plumbing-svg';
import SVGGround from '../../components/svg/ground-svg';
import SVGBrick from '../../components/svg/brick-svg';
import SVGCCTV from '../../components/svg/cctv-svg';
import SVGGas from '../../components/svg/gas-svg';
import SVGElectrical from '../../components/svg/electrical-svg';
import SVGRoofing from '../../components/svg/roofing-svg';
import SVGCarpentry from '../../components/svg/carpentry-svg';
import SVGPlastering from '../../components/svg/plastering-svg';
import HTMLReactParser from 'html-react-parser';

const MaintenancePage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Maintenance-Hero-MP" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      CImg: wpMediaItem(title: { eq: "Compliant-Image-MP" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      BBImg: wpMediaItem(title: { eq: "Bespoke-Builder-Image-MP" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      schemaLogo: wpMediaItem(title: { eq: "Colston-Logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
      CM: wpCommercialMaintenance {
        commercialMaintenanceFields {
          heroSection {
            btnText
            heading1
            heading1Body
            subheading
            title
            image {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          compliantSection {
            body
            title
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          specialistMaintenanceSection {
            body
            btnText
            subheading
            title
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          servicesSection {
            title
            services {
              title
              icon {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
            }
          }
          messageSection {
            body
            btnText
            heading1
            heading1Body
            heading2
            heading2Body
            subheading
            title
          }
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
  const CImg = data.CImg?.localFile.childImageSharp.gatsbyImageData;
  const BBImg = data.BBImg?.localFile.childImageSharp.gatsbyImageData;
  const CM = data.CM?.commercialMaintenanceFields;
  console.log(CM);

  const serviceSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    '@id':
      'https://www.colston.ltd.uk/services/commercial-maintenance/#commercial-maintenance',
    image: `https://www.colston.ltd.uk${data.heroImg.localFile.publicURL}`,
    mpn: 'https://www.colston.ltd.uk/services/commercial-maintenance',
    sku: 'https://www.colston.ltd.uk/services/commercial-maintenance',
    description:
      'We are committed to helping you maintain your commercial property by offering periodic and unscheduled maintenance checks.​',
    logo: `https://www.colston.ltd.uk${data.schemaLogo.localFile.publicURL}`,
    name: 'Colston Ltd',
    url: 'https://www.colston.ltd.uk',
    brand: {
      '@type': 'Organization',
      logo: `https://www.colston.ltd.uk${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.colston.ltd.uk${data.heroImg.localFile.publicURL}`,
      name: 'Colston Ltd',
      url: 'https://www.colston.ltd.uk',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      reviewCount: '57',
      ratingValue: '5',
      bestRating: '5',
      worstRating: '1',
    },
    // review: {
    // 	"@type": "Review",
    // 	url: "https://www.dentalhouseexeter.co.uk/reviews",
    // 	datePublished: "2022-03-21T17:58:29+00:00",
    // 	reviewBody:
    // 		"This is the best dental practice I have known. Not only in terms of expertise but the lovely sociability of every single member of the practice team.",
    // 	author: {
    // 		"@type": "Person",
    // 		name: "Caroline P",
    // 	},
    // 	reviewRating: {
    // 		"@type": "Rating",
    // 		ratingValue: "5",
    // 		bestRating: "5",
    // 		worstRating: "1",
    // 	},
    // 	publisher: {
    // 		"@type": "Organization",
    // 		name: "Dental House Exeter",
    // 		sameAs: "https://www.dentalhouseexeter.co.uk",
    // 	},
    // },
  };

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: '1',
        name: 'Home',
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        '@type': 'ListItem',
        position: '2',
        name: 'Services',
        item: {
          url: `${siteUrl}/services`,
          id: `${siteUrl}/services`,
        },
      },

      {
        '@type': 'ListItem',
        position: '3',
        name: 'Maintenance',
        item: {
          url: `${siteUrl}/services/maintenance`,
          id: `${siteUrl}/services/maintenance`,
        },
      },
    ],
  };

  return (
    <Layout>
      <div className='position-relative'>
        <Helmet>
          {' '}
          <script type='application/ld+json'>
            {JSON.stringify(breadcrumb)}
          </script>
        </Helmet>
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(serviceSchema)}
          </script>
        </Helmet>

        <GatsbySeo
          title='Commercial Property Maintenance | Colston'
          description='We are committed to helping you maintain your commercial property by offering periodic and unscheduled maintenance checks.'
          language='en'
          openGraph={{
            type: 'website',
            url: `${siteUrl}/services/maintenance`,
            title: 'Maintenance | Colston Ltd',
            description:
              'We are committed to helping you maintain your commercial property by offering periodic and unscheduled maintenance checks.',
            images: [
              {
                url: `${data.heroImg?.localFile.publicURL}`,
                width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
                height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
                alt: `${data.heroImg?.altText}`,
              },
            ],
          }}
        />
        <SVGMP1
          style={{ zIndex: '-1' }}
          className='position-absolute d-none d-lg-block top-10 start-0'
        />
        <SVGMP2
          style={{ zIndex: '-1', top: '25%' }}
          className='position-absolute d-none d-lg-block  end-0'
        />
        <SVGMP3
          style={{ zIndex: '-1' }}
          className='position-absolute d-none d-lg-block bottom-10 start-0'
        />
        <ServiceHero
          title={CM.heroSection.title}
          subTitle={CM.heroSection.heading1}
          description={HTMLReactParser(CM.heroSection.heading1Body)}
          image={
            CM.heroSection?.image.node?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          imageAlt={CM.heroSection?.image?.node?.altText}
          ctaText={CM.heroSection.subheading}
          btnText={CM.heroSection.btnText}
        />
        <ServiceHeroMobile
          title={CM.heroSection.title}
          subTitle={CM.heroSection.heading1}
          description={HTMLReactParser(CM.heroSection.heading1Body)}
          ctaText={CM.heroSection.subheading}
          btnText={CM.heroSection.btnText}
        />
        <section className='pt-10'>
          <ImageSection
            title={CM.compliantSection.title}
            image={
              CM.compliantSection?.image.node?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            imageAlt={CM.compliantSection?.image?.node?.altText}
            text={HTMLReactParser(CM.compliantSection.body)}
            btnText={CM.specialistMaintenanceSection?.btnText}
            imgSpan={6}
            textSpan={6}
            textPos='top-50 start--20 translate-middle-y'
            imgHeight='40rem'
            imgWidth='100%'
            rounded='left-rounded'
          />
        </section>
        <section className='pt-lg-10 pt-5'>
          <ImageSection
            title={CM.specialistMaintenanceSection.title}
            image={
              CM.specialistMaintenanceSection?.image.node?.localFile
                ?.childImageSharp?.gatsbyImageData
            }
            imageAlt={CM.specialistMaintenanceSection?.image?.node?.altText}
            text={HTMLReactParser(CM.specialistMaintenanceSection.body)}
            ctaText={CM.specialistMaintenanceSection?.subheading}
            btnText={CM.specialistMaintenanceSection?.btnText}
            order='last'
            imgSpan={6}
            textSpan={6}
            textPos='top-50 end--20 translate-middle-y'
            ctaPos='top-100 translate-middle start-100'
            imgWidth='100%'
            eHeight='50px'
            rounded='right-rounded'
            cta={true}
          />
        </section>
        <section>
          <Container className='pt-5 pt-lg-10 pt-xl-6'>
            <Row>
              <Col>
                <h2 className='pt-4 fs-1 pb-lg-10 pb-7 text-center text-primary'>
                  {CM.servicesSection?.title}
                </h2>
              </Col>
            </Row>
            <Row>
              {CM.servicesSection?.services?.map((i) => (
                <ServiceCard
                  key={i.title}
                  header={i.title}
                  icon={
                    <GatsbyImage
                      className='image-section-image'
                      style={{
                        borderRadius: '0px',
                        width: 55,
                        height: 55,
                      }}
                      image={
                        i.icon?.node?.localFile?.childImageSharp
                          ?.gatsbyImageData
                      }
                      alt={i.icon?.image?.node?.altText}
                    />
                  }
                />
              ))}
            </Row>
          </Container>
        </section>
        <section className='pb-lg-10 pb-5'>
          <TextSection
            title={CM.messageSection.title}
            text={
              <span>
                {HTMLReactParser(CM.messageSection.body)}
                <h3 className='text-primary py-3'>
                  {CM.messageSection.heading1}
                </h3>
                {HTMLReactParser(CM.messageSection.heading1Body)}
                <h3 className='text-primary py-3'>
                  {' '}
                  {CM.messageSection.heading2}
                </h3>
                {HTMLReactParser(CM.messageSection.heading2Body)}
              </span>
            }
            textSpan={8}
            eHeight='50px'
            ctaPos='top-100 start-50 translate-middle'
            cta={true}
            ctaText={CM.messageSection.subheading}
            btnText={CM.messageSection.btnText}
          />
        </section>
      </div>
    </Layout>
  );
};

export default MaintenancePage;
